/// <reference path="local-storage.js" />

(function () {
  const PREVIOUS_PAGE_STORAGE_KEY = "PreviousPage";
  const LISTNAME_KEY_PREFIX = "evogtm-pl-";

  var firstPageLoad = true;

  //helper manager class for Google Universal Analytics' dataLayer pushes
  function dataLayerManager(dataLayer) {
    var that = this,
      _cache = {};

    function setProductListName(uniqueId, listName) {
      evo.sessionStorage.setItem(LISTNAME_KEY_PREFIX + uniqueId, listName);
    }

    function getProductListName(uniqueId) {
      return evo.sessionStorage.getItem(LISTNAME_KEY_PREFIX + uniqueId);
    }

    //helper method that caches the products into the _productCache lookup
    //for later usage with product clicks and cart removal data layer pushes
    function cacheProducts(products) {
      if (!products || products.length < 1) {
        return;
      }

      for (var i = 0; i < products.length; i++) {
        var p = products[i];
        p.position = i + 1;
        if (p._uniqueId) {
          _cache[p._uniqueId] = p;
        }
      }
    }

    //helper method that builds and pushes a data layer definition object for products in cache
    function dataLayerPushForProduct(
      uniqueId,
      eventName,
      actionContainer,
      listName,
    ) {
      //make sure the product object was cached
      if (!uniqueId || !_cache[uniqueId]) {
        console.warn(
          "call to dataLayer.push " +
            eventName +
            " failed: product not in cache",
        );
        return;
      }

      //build the definition object for the push
      var product = _cache[uniqueId],
        definition = {
          event: eventName,
          ecommerce: {},
        };

      definition.ecommerce[actionContainer] = {
        products: [product],
      };

      if (product.list || listName) {
        definition.ecommerce[actionContainer].actionField = {
          list: product.list || listName,
        };
        // Store the list name for fetching in checkout
        setProductListName(uniqueId, product.list || listName);
      }

      //finally, push to the data layer (does the actual reporting to GA)
      dataLayer.push(definition);
    }

    // Google Tag Manager persists values in the dataLayer. Not a problem for
    // page refreshes, but for ajax updates need to reset the dataLayer so
    // that only the visible products are displayed
    function clearDataLayer() {
      dataLayer.push({
        ecommerce: undefined,
        event: undefined,
        eventCallback: undefined,
      });
    }

    //use instead of the standard dataLayer.push method to cache product instances
    //for later usage with product clicks and cart removals
    function pushToDataLayer(definition, flushDataLayer) {
      if (flushDataLayer) {
        clearDataLayer();
      }

      //special case for impressions and checkout steps
      //that caches products for later usage
      var ecommerce = definition.ecommerce;
      if (ecommerce) {
        if (ecommerce.checkout) {
          cacheProducts(ecommerce.checkout.products);
        } else {
          cacheProducts(ecommerce.impressions);
        }
      }

      dataLayer.push(definition);
    }

    // Pushes a page type event to the data layer
    // example: { event: 'evodatalayer', evoPageType: 'evo Trip' }
    function pushPageTypeEvent(pageType) {
      var pageTypeData = {
        event: "evodatalayer",
        evoPageType: pageType,
      };
      var pageTypeDataEvent = that.appendAndSetPreviousPage(
        pageType,
        "",
        "",
        pageTypeData,
      );

      dataLayer.push(pageTypeDataEvent);
    }

    that.pushEvoPageType = function (pageName) {
      pushPageTypeEvent(pageName);
    };

    // Push disable marketing event
    that.pushDisableMarketing = function () {
      const data = {
        event: "disablemarketing",
        value: true,
      };
      dataLayer.push(data);
    };

    //Cache the specified evoTrip objects for use at a later time
    that.cacheTrips = function (trips) {
      if (!Array.isArray(trips) || trips.length === 0) {
        console.warn("Unable to cache empty trips array");
        return;
      }

      trips.forEach(function (t) {
        if (t.sku) _cache[t.sku] = t;
      });
    };

    //wrapper of standard push event for normal usage
    that.push = function (data) {
      dataLayer.push(data);
    };

    //Add evoTrip destination data to the dataLayer
    that.pushDestinationView = function (destination) {
      if (!destination) {
        console.warn("Unable to push null destination to dataLayer");
        return;
      }

      dataLayer.push(destination);
    };

    //push evoTrip individual data to the dataLayer
    that.pushTripView = function (sku, dataToMerge) {
      var trip = _cache[sku || "-1"];
      if (!sku || !trip) {
        console.warn("Unable to push null trip to dataLayer");
        return;
      }

      // merge properties that are only known at view time
      if (
        dataToMerge &&
        dataToMerge.hasOwnProperty &&
        dataToMerge.hasOwnProperty.call
      ) {
        trip.inventory = dataToMerge.Inventory;
      }

      pushToDataLayer(trip);
    };

    ///Use to notify dataLayer of a product detail view
    that.pushDetailView = function (product) {
      if (!product) {
        console.warn("Unable to push null product to dataLayer");
        return;
      }

      clearDataLayer();

      var listName =
        getProductListName(product._uniqueId) ||
        "PDP - " +
          (product.IsOutlet ? "Outlet" : "Store") +
          " - " +
          (product.Used ? "Used" : "New");
      dataLayer.push({
        event: firstPageLoad ? "ecommerce" : "ecommerce-detail",
        ecommerce: {
          detail: {
            actionField: { list: listName },
            products: [product],
          },
        },
      });

      firstPageLoad = false;
      setProductListName(product._uniqueId, listName);
    };

    ///Use to notify dataLayer of a product added to the user's cart
    that.pushAddToCart = function (product, buttonContext) {
      if (!product) {
        console.warn("Unable to push null product to dataLayer");
        return;
      }

      clearDataLayer();

      dataLayer.push({
        button_context: buttonContext,
        ecommerce: {
          add: {
            products: [product],
          },
        },
        event: "addToCart",
      });
    };

    //Use to notify the dataLayer that a product click has occurred
    that.pushProductClick = function (uniqueId, listName) {
      dataLayerPushForProduct(uniqueId, "productClick", "click", listName);
    };

    //Use to notify the dataLayer that an item has been removed from the cart
    that.pushRemoveFromCart = function (uniqueId) {
      var list = getProductListName(uniqueId);
      dataLayerPushForProduct(uniqueId, "removeFromCart", "remove", list);
    };

    // Use to notify GA that a promotion has been viewed
    // promoObjects is an array of:
    // { name: 'Promotion Name, i.e. ""', creative: 'creative_asset_name', position: 'hero_slot_1' }
    that.pushPromoViews = function (promoObjects) {
      var promos = _.filter(promoObjects || [], function (p) {
        if (!p || (!p.id && !p.name)) {
          console.warn("promoView must have id or name value");
          return false;
        }
        return true;
      });

      if (!promos.length) {
        console.warn("no valid promo objects found to push");
        return;
      }

      pushToDataLayer(
        {
          event: "promotionImpression",
          ecommerce: {
            promoView: {
              promotions: promos,
            },
          },
        },
        true,
      );
    };

    // Use to notify GA that a promotion has been clicked
    // promoObject is an object like: { name: 'Promotion Name', creative: 'creative_asset_name', position: 'hero_slot_1' }
    that.pushPromoClick = function (promoObject) {
      if (!promoObject || (!promoObject.id && !promoObject.name)) {
        console.warn("promoClick must have id or name value");
        return;
      }

      pushToDataLayer(
        {
          event: "promotionClick",
          ecommerce: {
            promoClick: {
              promotions: [promoObject],
            },
          },
        },
        true,
      );
    };

    // Use to add data to a collection of incomplete impressionFieldObject data
    // and then format it as a product impressions measurement and push it.
    that.pushImpressions = function (impressions, list, flushDataLayer) {
      if (!impressions || !impressions.length) return;

      //build the definition object for the push
      for (var i = 0; i < impressions.length; i++) {
        impressions[i].list = list;
      }

      pushToDataLayer(
        {
          ecommerce: {
            currencyCode: "USD",
            impressions: impressions,
          },
          event: "ecommerce",
        },
        flushDataLayer,
      );
    };

    that.pushData = function (propertyName, value, flushData) {
      if (!propertyName) return;

      var data = {};
      data[propertyName] = value;
      pushToDataLayer(data, flushData === true);
    };

    // use to push a generic event to the data layer
    that.pushEvent = function (category, action, label, value, nonInteraction) {
      var data = {
        action: action,
        category: category,
        event: category + "||" + action,
        label: label,
        nonInteraction: nonInteraction,
        value: value,
      };
      pushToDataLayer(data);
    };

    // use to push a generic custom variable to the data layer
    that.pushCustomVariable = function (variableName, value) {
      var data = {};
      data[variableName] = value;
      pushToDataLayer(data);
    };

    that.appendAndSetPreviousPage = function (
      currentPageType,
      currentCoreTaxonomyNodePath,
      currentProductSaleStatus,
      dataLayerModel,
    ) {
      try {
        var previousPage = evo.sessionStorage.getItem(
          PREVIOUS_PAGE_STORAGE_KEY,
          true,
        );

        if (previousPage) {
          dataLayerModel["previousPage"] = {
            previousPageType: previousPage.previousPageType,
            previousPageCoreTaxonomyNode:
              previousPage.previousPageCoreTaxonomyNode,
            previousPageSaleStatus: previousPage.previousPageSaleStatus,
          };
        }

        var currentPageData = {
          previousPageType: currentPageType ? currentPageType : "",
          previousPageCoreTaxonomyNode: currentCoreTaxonomyNodePath
            ? currentCoreTaxonomyNodePath
            : "",
          previousPageSaleStatus: currentProductSaleStatus
            ? currentProductSaleStatus
            : "",
        };

        evo.sessionStorage.setItem(
          PREVIOUS_PAGE_STORAGE_KEY,
          currentPageData,
          null,
          true,
        );
      } catch (exception) {
        // do nothing
      }

      return dataLayerModel;
    };
  }

  window.dataLayerManager = new dataLayerManager(dataLayer);
})();
